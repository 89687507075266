import { tryGetGaStatus } from "./src/utils/local-storage"

export function onRouteUpdate({ location }) {
  /* analytics */
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined
    window.gtag(`event`, `page_view`, { page_path: pagePath })
  }
  if (process.env.NODE_ENV === `production`) {
    let gaStatus = tryGetGaStatus()
    if (gaStatus === "enabled") {
      // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
      if (`requestAnimationFrame` in window) {
        requestAnimationFrame(() => {
          requestAnimationFrame(sendPageView)
        })
      } else {
        setTimeout(sendPageView, 32)
      }
    } else if (gaStatus === "disabled") {
      window["ga-disable-UA-163969847-1"] = true
    }
  }
}
