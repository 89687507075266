export const tryGetTheme = () => {
  const defaultTheme = "light"
  try {
    let theme = localStorage.getItem("theme")
    return theme || defaultTheme
  } catch (e) {
    return defaultTheme
  }
}
export const trySetTheme = (theme) => {
  try {
    localStorage.setItem("theme", theme)
  } catch (e) {}
}

export const tryGetGaStatus = () => {
  const defaultValue = "enabled"
  try {
    let gaDisable = localStorage.getItem("ga-status")
    return gaDisable || defaultValue
  } catch (e) {
    return defaultValue
  }
}

export const trySetGaStatus = (gaStatus) => {
  try {
    localStorage.setItem("ga-status", gaStatus)
  } catch (e) {}
}

export const tryGetBlogReadingMode = () => {
  const defaultReadingMode = "column"
  try {
    let readingMode = localStorage.getItem("blog-reading-mode")
    return readingMode || defaultReadingMode
  } catch (e) {
    return defaultReadingMode
  }
}
export const trySetBlogReadingMode = (readinMode) => {
  try {
    localStorage.setItem("blog-reading-mode", readinMode)
  } catch (e) {}
}
